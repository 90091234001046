






























import {
  defineComponent,
  ref
} from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import CategoryWatermark from '~/components/Headless/Watermarks/CategoryWatermark.vue';
import CircleGradient from '~/components/Headless/Watermarks/CircleGradient.vue';
export default defineComponent({
  name: "HeadlessGallery",
  components: {
    SvgImage, CategoryWatermark, CircleGradient
  },
  props: {
    imageArray: {
      type: [Object, Array],
      default: () => [
        {
          url: ''
        },
      ]
    }
  },
  setup(props) {
    const isOpen = ref(false);
    const activeImage = ref("");
    const imageCount = ref(0);
    const isClickableLeft = ref(true);
    const isClickableRight = ref(true);
    const selectActiveImage = (count) => {
      imageCount.value = count;
      activeImage.value = props.imageArray[count]?.url;
      if (imageCount.value == 0) {
        isClickableLeft.value = false;
      } else if (imageCount.value == props.imageArray.length - 1) {
        isClickableRight.value = false;
      } else {
        isClickableLeft.value = true;
        isClickableRight.value = true;
      }
    }

    const changeImage = (direction) => {
      if (direction == 'left') {
        if (imageCount.value == 1) {
          isClickableLeft.value = false;
          imageCount.value--;
        } else {
          imageCount.value--;
          isClickableRight.value = true;
        }
      } else {
        if (imageCount.value == (props.imageArray.length - 2)) {
          isClickableRight.value = false;
          imageCount.value++;
        } else {
          imageCount.value++;
          isClickableLeft.value = true;
        }
      }

      activeImage.value = props.imageArray[imageCount.value]?.url;

    }
    return {
      isOpen, activeImage, selectActiveImage, props, imageCount, isClickableLeft, isClickableRight, changeImage
    }
  }
});
