



























import { defineComponent, useAsync, ref, useContext, onMounted,
  computed, useMeta } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import HeadlessTwoColBanner from '~/components/Headless/TwoColBanner.vue';
import CategoryWatermark from '~/components/Headless/Watermarks/CategoryWatermark.vue';
import CircleGradient from '~/components/Headless/Watermarks/CircleGradient.vue';
import HeadlessGallery from '~/components/Headless/Gallery.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { setSirvImage } from '~/helpers/headless/helpers';

export default defineComponent({
  components: {
    SvgImage, HeadlessTwoColBanner, CategoryWatermark, CircleGradient, HeadlessGallery
  },
  setup(props, ctx) {
    const page = ref({
      title: '',
      meta_description: '',
      meta_keywords: '',
      og_image: ''
    });
    const wedding = ref(null);
    const weddingGalleryArray = ref(null);
    const { route, $strapi } = useContext();
    const { addTags } = useCache();
    const qs = require('qs');
    const query = qs.stringify({
      populate: [
        'image.media',
        'gallery.media'
      ],
    }, {
      encodeValuesOnly: true, // prettify URL
    });

    useAsync(async () => {
      const slug = route.value.meta.length ? route.value.meta.slug : route.value.path.split('/')[2];
      const { data } = await $strapi.find('weddings?filters[slug][$eq]=' + slug + '&' + query);
      wedding.value = data[0]?.attributes;
      weddingGalleryArray.value = data[0]?.attributes?.gallery.data.map(item => {return {url: setSirvImage(item.attributes.url,700), alt: item.attributes.alt}});
      page.value.title = wedding.value.title;
      page.value.meta_description = wedding.value.title;
      page.value.og_image = wedding.value.image?.data?.attributes?.url;
    });

    // Set seasons icon
    // Use of slice due to Strapi returning enumaration fields value with commas
    const setSeasonIcon = (name: string) => {
      if (name.includes(',')) {
        name = name.slice(0, -1);
      }
      return name;
    }

    // Set cache
    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: route.value.params.slug }]);
    })

    const routePath = route.value.path;

    return { wedding, weddingGalleryArray, setSeasonIcon, page, setSirvImage, routePath }
  },
  head() {
    return getMetaInfo(this.page);
  },
})
